export namespace VueRouter {
	export namespace Global {
		export enum View00 {
			NAME = 'LOGIN',
			PATH = '/'
		}

		export enum View01 {
			NAME = 'RESET_PASSWORD',
			PATH = '/restablecer/password'
		}

		export enum View02 {
			NAME = 'USER_PROFILE',
			PATH = '/usuario/perfil'
		}
	}

	export namespace Modules {
		export enum View10 {
			NAME = 'INTERNAL_CPANEL',
			PATH = '/cpanel/interno'
		}

		export enum View11 {
			NAME = 'EXTERNAL_CPANEL',
			PATH = '/cpanel/cliente'
		}

		export enum View12 {
			NAME = 'OPERATORS_MANAGER',
			PATH = '/gestion/operarios'
		}

		export enum View13 {
			NAME = 'INTERNAL_STORAGES_MANAGER',
			PATH = '/gestion/ubicaciones/internas'
		}

		export enum View20 {
			NAME = 'EXTERNAL_USERS',
			PATH = '/administracion/usuarios/clientes'
		}

		export enum View21 {
			NAME = 'INTERNAL_USERS',
			PATH = '/administracion/usuarios/internos'
		}

		export enum View22 {
			NAME = 'STORAGES',
			PATH = '/administracion/ubicaciones'
		}

		export enum View23 {
			NAME = 'SETTINGS',
			PATH = '/administracion/configuraciones'
		}

		export enum View30 {
			NAME = 'SERVICES_CPANEL',
			PATH = '/servicios/cpanel'
		}

		export enum View40 {
			NAME = 'CHECKLIST_CPANEL',
			PATH = '/checklist/cpanel'
		}

		export enum View41 {
			NAME = 'SETUP_CHECKLIST_CPANEL',
			PATH = '/setup/checklist/cpanel'
		}
	}

	export enum ViewModes {
		EXTERNAL,
		INTERNAL
	}
}